import { getRevertReasonFromErrorMessage } from "../../utils/getRevertReason";
import {
  showNotification,
  closeAllNotifications,
  getExplorerMeta
} from "@/utils/utils";
import { store } from "@/store";
import formatError from "@/utils/formatError";
import { signer } from "@/utils/wallet";

export const listenForConfirmations = async (
  receipt,
  customWaitingForTransactionApprovalMessage,
  customMiningMessage,
  customSuccessMessage,
  confirmations
) => {
  try {
    closeAllNotifications();

    const explorerMeta = await getExplorerMeta(receipt.hash, "tx");

    showNotification(
      "success",
      customMiningMessage ||
        `transaction processing, please wait... ${explorerMeta.typeMessageWithLinkShort}`,
      undefined,
      0
    );

    const transaction = await receipt.wait(
      confirmations && typeof confirmations === "number"
        ? confirmations
        : store.state.network === "hardhat"
        ? 1
        : 8
    );

    // TODO: This is only called here because the SDK is not currently updating
    // the vaults after a transaction changes their state.
    // The SDK needs to be updated to account for this which should be done via
    // a WebSocket connection to the graph node after indexing the new state.
    try {
      const sdk = store.state.refHandleSDK.get();
      const promises = [];
      for (let vault of sdk.vaults) {
        promises.push(vault.updateFromChain());
      }
      await Promise.all(promises);
    } catch (error) {
      console.log(`Failed to update vaults: ${error}`);
    }

    closeAllNotifications();

    showNotification(
      "success",
      customSuccessMessage
        ? await customSuccessMessage(
            transaction,
            explorerMeta.typeMessageWithLink
          )
        : `transaction successful. ${explorerMeta.typeMessageWithLink}`,
      undefined,
      0
    );

    return transaction;
  } catch (error) {
    console.error(error);
    const reason =
      (await getRevertReasonFromErrorMessage(error)) ?? error.message;
    throw new Error(await formatError(reason));
  }
};

export default async (
  tx,
  customWaitingForTransactionApprovalMessage,
  customMiningMessage,
  customSuccessMessage,
  confirmations
) => {
  if (!store.state.isLite) {
    showNotification(
      "warning",
      customWaitingForTransactionApprovalMessage ||
        `waiting for transaction approval...`,
      undefined,
      0
    );
  }

  let receipt;
  try {
    receipt = await signer.sendTransaction(tx);
  } catch (error) {
    console.error(error);
    const reason =
      (await getRevertReasonFromErrorMessage(error)) ?? error.message;
    throw new Error(await formatError(reason));
  }

  return listenForConfirmations(
    receipt,
    customWaitingForTransactionApprovalMessage,
    customMiningMessage,
    customSuccessMessage,
    confirmations
  );
};
