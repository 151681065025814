<template>
  <div class="wallet">
    <div class="hfi-wallet-table-header uk-flex uk-flex-between uk-flex-middle">
      <h4 class="uk-margin-remove-bottom">
        <i class="fal fa-wallet uk-margin-xsmall-right"></i>
        <span
          class="cursor-pointer"
          uk-tooltip="title: shows funds in your connected wallet; pos: right"
        >
          wallet
        </span>
      </h4>

      <div class="hfi-totals">
        <span
          class="uk-margin-remove-vertical"
          v-if="network === Network.arbitrum"
        >
          eligible collateral:
          {{ currency }}
          {{ totalValues.collateral.toLocaleString(undefined, digits(2)) }},
        </span>

        <span class="uk-margin-remove-vertical">
          fxTokens:
          {{ currency }}
          {{ totalValues.fxTokens.toLocaleString(undefined, digits(2)) }}
        </span>
      </div>

      <SelectCurrency
        class="hfi-select-currency"
        inputClasses="hfi-currency-button"
        v-on:change="(selection) => setCurrency(selection)"
        :disabled="loading || !network || !sdk"
        :value="currency"
      />
    </div>

    <table
      style="margin-bottom: 15px"
      class="uk-table uk-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
    >
      <thead class="hfi-wallet-table-header hfi-border-top-remove">
        <tr v-if="loading || assets.length > 0" class="hfi-wallet-header-row">
          <th scope="col" class="hfi-token-column">assets</th>
          <th scope="col" class="uk-text-right">amount</th>
          <th scope="col" class="uk-text-right">
            price ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-right">
            value ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-center hfi-action-column"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="loading">
          <td>
            <vue-loaders-ball-pulse
              color="currentColor"
              scale="0.5"
              class="uk-text-middle"
              style="margin-top: -3px"
            />
          </td>
        </tr>

        <tr
          v-else
          v-for="asset in assets.filter(
            (as) => balances[as.symbol] && balances[as.symbol].gt(0)
          )"
          :key="asset.symbol"
        >
          <td data-label="asset" class="uk-flex uk-flex-middle uk-text-nowrap">
            <a
              v-if="asset.symbol === Token.FOREX"
              data-label="asset"
              class="hfi-dashboard-icon-button uk-text-nowrap"
              :href="asset.explorerMeta.typeUrl"
              target="_blank"
              :uk-tooltip="`title: ${asset.explorerMeta.typeMessage}; pos: right;`"
            >
              <img width="22" src="FOREXLogoLightBorder.svg" />
              {{ asset.symbol }}
              <i class="fal fa-external-link-square"></i>
            </a>

            <span v-else>
              <img
                width="22"
                :src="`${
                  tokenList.find((tok) => tok.symbol === asset.symbol)
                    ? tokenList.find((tok) => tok.symbol === asset.symbol).icon
                    : 'handle.fiTokenPlaceholder.png'
                }`"
              />
              {{ asset.symbol }}
            </span>
          </td>

          <td data-label="amount" class="uk-text-right">
            {{ formatEther(balances[asset.symbol], undefined, asset.decimals) }}
          </td>

          <td data-label="price" class="uk-text-right">
            {{ prices[asset.symbol].toLocaleString(undefined, digits(2)) }}
          </td>

          <td data-label="value" class="uk-text-right">
            {{ values[asset.symbol].toLocaleString(undefined, digits(2)) }}
          </td>

          <td data-label="actions">
            <div class="uk-button-group">
              <button
                :id="'sendButton' + capitalise(asset.symbol)"
                class="uk-icon-button hfi-button-group-button-first"
                @click.prevent="send(asset.symbol, asset.decimals)"
                :disabled="
                  !balances[asset.symbol] || balances[asset.symbol].eq(0)
                "
                :uk-tooltip="
                  balances[asset.symbol] && balances[asset.symbol].gt(0)
                    ? `title: send ${asset.symbol}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-share"></i>
              </button>
              <button
                :id="'convertButton' + capitalise(asset.symbol)"
                class="uk-icon-button hfi-button-group-button-last"
                @click.prevent="convert(asset.symbol)"
                :disabled="
                  convertNetworks && !convertNetworks.includes(network)
                "
                :uk-tooltip="
                  !convertNetworks || convertNetworks.includes(network)
                    ? `title: convert ${asset.symbol}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-exchange"></i>
              </button>
            </div>
          </td>
        </tr>

        <tr v-if="network === Network.arbitrum" class="hfi-wallet-header-row">
          <th scope="col" class="hfi-token-column">eligible collateral</th>
          <th scope="col" class="uk-text-right">amount</th>
          <th scope="col" class="uk-text-right">
            price ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-right">
            value ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-center hfi-action-column"></th>
        </tr>

        <tr v-if="network === Network.arbitrum && balances[nativeToken]">
          <td
            scope="row"
            data-label="eligible collateral"
            class="uk-flex uk-flex-middle uk-text-nowrap"
            style="margin-top: -0.5px"
          >
            <span data-label="eligible collateral">
              <img
                width="22"
                :src="`${
                  tokenList.find((tok) => tok.symbol === nativeToken)
                    ? tokenList.find((tok) => tok.symbol === nativeToken).icon
                    : 'handle.fiTokenPlaceholder.png'
                }`"
              />
              {{ nativeToken }}
            </span>
          </td>

          <td data-label="amount" class="uk-text-right">
            {{ formatEther(balances[this.nativeToken]) }}
          </td>

          <td data-label="price" class="uk-text-right">
            {{
              parseFloat(prices[this.nativeToken]).toLocaleString(
                undefined,
                digits(2)
              )
            }}
          </td>

          <td data-label="value" class="uk-text-right">
            {{
              parseFloat(values[this.nativeToken]).toLocaleString(
                undefined,
                digits(2)
              )
            }}
          </td>

          <td data-label="actions">
            <div class="uk-button-group">
              <button
                id="sendButtonNative"
                class="uk-icon-button hfi-button-group-button hfi-button-group-button-first"
                @click.prevent="send(nativeToken)"
                :disabled="
                  !balances[nativeToken] || balances[nativeToken].eq(0)
                "
                :uk-tooltip="
                  balances[nativeToken] && balances[nativeToken].gt(0)
                    ? `title: send ${nativeToken}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-share"></i>
              </button>
              <button
                id="convertButtonNative"
                class="uk-icon-button hfi-button-group-button hfi-button-group-button-last"
                :disabled="
                  convertNetworks && !convertNetworks.includes(network)
                "
                @click.prevent="convert(nativeToken)"
                :uk-tooltip="
                  !convertNetworks || convertNetworks.includes(network)
                    ? `title: convert ${nativeToken}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-exchange"></i>
              </button>
            </div>
          </td>
        </tr>

        <tr
          v-if="
            network === Network.arbitrum &&
            (loading || !balances || !prices || !values)
          "
        >
          <td>
            <vue-loaders-ball-pulse
              color="currentColor"
              scale="0.5"
              class="uk-text-middle"
              style="margin-top: -3px"
            />
          </td>
        </tr>

        <tr
          v-else
          v-for="walletCollateral in walletCollaterals.filter(
            (collateralToken) =>
              balances[collateralToken.symbol] &&
              balances[collateralToken.symbol].gt(0) &&
              prices[collateralToken.symbol] &&
              values[collateralToken.symbol]
          )"
          :key="walletCollateral.symbol"
        >
          <td
            data-label="eligible collateral"
            class="uk-flex uk-flex-middle uk-text-nowrap"
          >
            <a
              data-label="eligible collateral"
              class="hfi-dashboard-icon-button uk-text-nowrap"
              :href="walletCollateral.explorerMeta.typeUrl"
              target="_blank"
              :uk-tooltip="`title: ${walletCollateral.explorerMeta.typeMessage}; pos: right;`"
            >
              <img
                width="22"
                :src="`${
                  walletCollateral.symbol === Token.FOREX
                    ? 'FOREXLogoLightBorder.svg'
                    : tokenList.find(
                        (tok) => tok.symbol === walletCollateral.symbol
                      )
                    ? tokenList.find(
                        (tok) => tok.symbol === walletCollateral.symbol
                      ).icon
                    : 'handle.fiTokenPlaceholder.png'
                }`"
              />

              {{ walletCollateral.symbol }}

              <i class="fal fa-external-link-square"></i>
            </a>
          </td>

          <td data-label="amount" class="uk-text-right">
            {{
              formatEther(
                balances[walletCollateral.symbol],
                undefined,
                walletCollateral.decimals
              )
            }}
          </td>

          <td data-label="price" class="uk-text-right">
            {{
              prices[walletCollateral.symbol].toLocaleString(
                undefined,
                digits(2)
              )
            }}
          </td>

          <td data-label="value" class="uk-text-right">
            {{
              values[walletCollateral.symbol].toLocaleString(
                undefined,
                digits(2)
              )
            }}
          </td>

          <td data-label="actions">
            <div class="uk-button-group">
              <button
                :id="'sendButton' + capitalise(walletCollateral.symbol)"
                class="uk-icon-button hfi-button-group-button-first"
                @click.prevent="
                  send(walletCollateral.symbol, walletCollateral.decimals)
                "
                :disabled="
                  !balances[walletCollateral.symbol] ||
                  balances[walletCollateral.symbol].eq(0)
                "
                :uk-tooltip="
                  balances[walletCollateral.symbol] &&
                  balances[walletCollateral.symbol].gt(0)
                    ? `title: send ${walletCollateral.symbol}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-share"></i>
              </button>
              <button
                :id="'convertButton' + capitalise(walletCollateral.symbol)"
                class="uk-icon-button hfi-button-group-button-last"
                @click.prevent="convert(walletCollateral.symbol)"
                :disabled="
                  convertNetworks && !convertNetworks.includes(network)
                "
                :uk-tooltip="
                  !convertNetworks || convertNetworks.includes(network)
                    ? `title: convert ${walletCollateral.symbol}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-exchange"></i>
              </button>
            </div>
          </td>
        </tr>

        <tr class="hfi-wallet-header-row row-2">
          <th scope="col" class="hfi-token-column">fxTokens</th>
          <th scope="col" class="uk-text-right">amount</th>
          <th scope="col" class="uk-text-right">
            price ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-right">
            value ({{ currency === "" ? this.defaultCurrency : currency }})
          </th>
          <th scope="col" class="uk-text-center"></th>
        </tr>

        <tr
          v-if="
            !Object.keys(balances).some(
              (token) =>
                token.includes('fx') && balances[token] && balances[token].gt(0)
            )
          "
        >
          <td v-if="loading">
            <vue-loaders-ball-pulse
              color="currentColor"
              scale="0.5"
              class="uk-text-middle"
              style="margin-top: -3px"
            />
          </td>
          <td v-else>
            {{ account ? "no fxTokens found" : "no wallet connected" }}
          </td>
        </tr>
        <tr
          v-else-if="!loading"
          v-for="walletFxToken in walletFxTokens.filter(
            (fxToken) =>
              balances[fxToken.symbol] && balances[fxToken.symbol].gt(0)
          )"
          :key="walletFxToken.symbol"
        >
          <td
            data-label="fxToken"
            class="uk-flex uk-flex-middle uk-text-nowrap"
          >
            <a
              data-label="fxToken"
              class="hfi-dashboard-icon-button uk-text-nowrap"
              :href="walletFxToken.explorerMeta.typeUrl"
              target="_blank"
              :uk-tooltip="`title: ${walletFxToken.explorerMeta.typeMessage}; pos: right;`"
            >
              <img width="22" :src="`${walletFxToken.symbol}Logo.png`" />

              {{ walletFxToken.symbol }}

              <i class="fal fa-external-link-square"></i>
            </a>
          </td>

          <td data-label="amount" class="uk-text-right">
            {{ formatEther(balances[walletFxToken.symbol]) }}
          </td>

          <td data-label="price" class="uk-text-right">
            {{
              prices[walletFxToken.symbol].toLocaleString(
                undefined,
                digits(walletFxToken.symbol === "fxKRW" ? 6 : 4)
              )
            }}
          </td>

          <td data-label="value" class="uk-text-right">
            {{
              values[walletFxToken.symbol].toLocaleString(undefined, digits(2))
            }}
          </td>

          <td data-label="actions">
            <div class="uk-button-group">
              <button
                :id="'sendButton' + capitalise(walletFxToken.symbol)"
                class="uk-icon-button hfi-button-group-button hfi-button-group-button-first"
                @click.prevent="send(walletFxToken.symbol)"
                :disabled="
                  !balances[walletFxToken.symbol] ||
                  balances[walletFxToken.symbol].eq(0)
                "
                :uk-tooltip="
                  balances[walletFxToken.symbol] &&
                  balances[walletFxToken.symbol].gt(0)
                    ? `title: send ${walletFxToken.symbol}; pos: right;`
                    : undefined
                "
              >
                <i class="fal fa-share"></i>
              </button>
              <button
                :id="'convertButton' + capitalise(walletFxToken.symbol)"
                class="uk-icon-button hfi-button-group-button hfi-button-group-button"
                @click.prevent="convert(walletFxToken.symbol)"
              >
                <i class="fal fa-exchange"></i>
              </button>
              <button
                :id="'addTokenButton' + capitalise(walletFxToken.symbol)"
                class="uk-icon-button hfi-button-group-button hfi-button-group-button-last"
                @click.prevent="addToken(walletFxToken.symbol)"
                :uk-tooltip="`title: add ${walletFxToken.symbol} token to wallet; pos: right;`"
              >
                <i class="fal fa-plus"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <SendModal
      :token="sendToken"
      :balance="sendTokenBalance"
      :decimals="sendTokenDecimals"
      @sent="resetState"
    />
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import {
  getDecimalsAmount,
  getExplorerMeta,
  closeAllNotifications,
  showNotification,
  getBalances,
} from "@/utils/utils";
import Token from "@/types/Token";
import { addEventListener, removeEventListener } from "../utils/event";
import Event from "@/types/Event";
import SelectCurrency from "@/components/SelectCurrency";
import { getPrice, getUSDPrice } from "../utils/prices";
import router from "@/router";
import SendModal from "@/components/SendModal";
import UIkit from "uikit";
import { addAssetToWallet } from "@/utils/wallet";
import config from "@/contracts.config.json";
import { signer, homesteadProvider, polygonProvider } from "@/utils/wallet";
import Network from "@/types/Network";

const DEFAULT_CURRENCY = "USD";

export default {
  name: "Wallet",
  components: {
    SelectCurrency,
    SendModal,
  },

  data() {
    return {
      loading: true,
      defaultCurrency: DEFAULT_CURRENCY,
      nativeBalance: 0,
      nativePrice: 0,
      /** @type { tokenRate: ethers.BigNumber, explorerMeta: object, token: string } */
      walletFxTokens: [],
      /** @type { tokenRate: ethers.BigNumber, explorerMeta: object, token: string } */
      walletCollaterals: [],
      /** fxToken alias => balance */
      balances: {},
      /** fxToken alias => value in chosen currency */
      prices: {},
      /** fxToken alias => price in chosen currency */
      values: {},
      /** fxAUD quote in Ether */
      audEthRate: 0,
      oneEth: ethers.utils.parseEther("1"),
      /** Token currently selected for management (e.g. deposit/withdrawal) */
      token: Token.fxAUD,
      /** Collateral currently selected for management */
      collateral: Token.WETH,
      ethers,
      Token,
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
      walletUpdateEventId: null,
      currency: "",
      ethCurrencyRate: 0,
      totalValues: {
        fxTokens: 0,
        collateral: 0,
      },
      sendToken: "",
      sendTokenBalance: ethers.BigNumber.from(0),
      sendTokenDecimals: 18,
      convertNetworks: null,
      config,
      homesteadProvider,
      polygonProvider,
      assets: [],
      Network,
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    provider() {
      return this.network === Network.homestead ||
        this.network === Network.polygon
        ? this[this.network + "Provider"]
        : this.sdk?.provider
        ? this.sdk.provider
        : null;
    },
    fxTokens() {
      return this.config[this.network].fxTokens;
    },
    collaterals() {
      return this.config[this.network].collaterals;
    },
    nativeToken() {
      return this.network === Network.polygon ? "MATIC" : "ETH";
    },
    sendModal() {
      return UIkit.modal("#send-modal");
    },
    tokenList() {
      return store.state.tokenList;
    },
  },
  async mounted() {
    await this.initialiseState();
  },

  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
    closeAllNotifications();
  },

  watch: {
    async network() {
      await this.resetState();
    },
    async account() {
      await this.resetState();
    },
    async sdk(newSdk, oldSdk) {
      if (newSdk?.network === oldSdk?.network) return;
      await this.resetState();
    },
  },

  methods: {
    initialiseState: async function () {
      this.convertNetworks = this.$router.resolve({
        name: "Convert",
      }).route.meta?.networks;
      await this.resetState();
    },

    async resetState() {
      this.loading = true;

      this.balances = {};
      this.prices = {};
      this.totalValues = {
        fxTokens: 0,
        collateral: 0,
      };
      this.nativeBalance = 0;
      this.nativePrice = 0;

      if (this.account && this.network && this.provider && this.sdk) {
        await this.showWallet();
      }

      this.$emit("loaded", true);
      this.loading = false;
    },

    async showWallet() {
      await this.setCurrency(DEFAULT_CURRENCY);
      try {
        this.audEthRate = this.sdk.protocol.getFxTokenBySymbol(Token.fxAUD);
      } catch (e) {
        console.log("Get fxToken error", e);
      }

      const fxTokens = this.fxTokens;
      this.walletFxTokens = [];

      for (let fxToken in fxTokens) {
        if (fxTokens[fxToken] === "") continue;

        if (!this.sdk?.protocol) continue;
        const fxTokenInstance = this.sdk.protocol.fxTokens.find(
          (fxTok) => fxTok.symbol === fxToken
        );
        if (!fxTokenInstance) continue;

        this.walletFxTokens.push({
          symbol: fxToken,
          address: fxTokens[fxToken],
          decimals: fxTokenInstance.decimals,
          rate: fxTokenInstance.rate,
          explorerMeta: await getExplorerMeta(fxTokens[fxToken], "token"),
        });
      }

      const collateralTokens = this.collaterals;
      this.assets = [];

      if (!collateralTokens[Token.FOREX])
        this.assets.push({
          symbol: Token.FOREX,
          address: collateralTokens[Token.FOREX],
          decimals: 18,
          rate: ethers.BigNumber.from(5),
          explorerMeta: await getExplorerMeta(
            collateralTokens[Token.FOREX],
            "token"
          ),
        });

      if (this.network !== Network.arbitrum)
        this.assets.push({
          symbol: this.nativeToken,
          address: collateralTokens[this.nativeToken],
          decimals: 18,
          rate: ethers.BigNumber.from(5),
          explorerMeta: await getExplorerMeta(
            collateralTokens[this.nativeToken],
            "token"
          ),
        });

      this.walletCollaterals = [];

      for (let collateral in collateralTokens) {
        if (collateralTokens[collateral] === "") continue;
        if (
          this.network !== Network.arbitrum &&
          collateral === this.nativeToken
        )
          continue;

        if (!this.sdk?.protocol) continue;
        const collateralTokenInstance = this.sdk.protocol.collateralTokens.find(
          (collateralTok) => collateralTok.symbol === collateral
        );
        if (!collateralTokenInstance) continue;

        this.walletCollaterals.push({
          symbol: collateral,
          address: collateralTokens[collateral],
          decimals: collateralTokenInstance.decimals,
          rate: collateralTokenInstance.rate,
          explorerMeta: await getExplorerMeta(
            collateralTokens[collateral],
            "token"
          ),
        });
      }

      this.balances = await getBalances(this.account, this.provider, [
        ...this.walletFxTokens,
        ...this.walletCollaterals,
        ...this.assets,
      ]);

      this.nativeBalance = this.balances[
        this.nativeToken
      ] = await signer.getBalance();
      this.nativePrice = this.prices[this.nativeToken] =
        parseFloat(ethers.utils.formatEther(this.oneEth)) /
        this.ethCurrencyRate;
      this.values[this.nativeToken] =
        parseFloat(ethers.utils.formatEther(this.nativeBalance)) *
        this.nativePrice;

      if (this.network === Network.arbitrum)
        this.totalValues.collateral = this.values[this.nativeToken];

      if (this.walletFxTokens.length === 0) return;

      for (let walletFxToken of this.walletFxTokens) {
        walletFxToken.balance = this.balances[walletFxToken.symbol];
        this.prices[walletFxToken.symbol] =
          parseFloat(ethers.utils.formatEther(walletFxToken.rate)) /
          this.ethCurrencyRate;
        this.values[walletFxToken.symbol] = this.balances[walletFxToken.symbol]
          ? parseFloat(
              ethers.utils.formatEther(this.balances[walletFxToken.symbol]) *
                parseFloat(
                  ethers.utils.formatEther(walletFxToken.rate) /
                    this.ethCurrencyRate
                )
            )
          : 0;
        this.totalValues.fxTokens += this.values[walletFxToken.symbol];
      }

      for (let walletCollateral of this.walletCollaterals) {
        const symbol = walletCollateral.symbol;
        walletCollateral.balance = this.balances[symbol];
        this.prices[symbol] =
          parseFloat(ethers.utils.formatEther(walletCollateral.rate)) /
          this.ethCurrencyRate;
        this.values[symbol] = this.balances[symbol]
          ? (ethers.utils.formatUnits(
              this.balances[symbol],
              walletCollateral.decimals
            ) *
              parseFloat(ethers.utils.formatEther(walletCollateral.rate))) /
            this.ethCurrencyRate
          : 0;

        this.totalValues.collateral += this.values[symbol];
      }

      for (let asset of this.assets) {
        asset.balance = this.balances[asset.symbol];
        this.prices[asset.symbol] =
          parseFloat(ethers.utils.formatEther(asset.rate)) /
          this.ethCurrencyRate;
        this.values[asset.symbol] = this.balances[asset.symbol]
          ? parseFloat(
              ethers.utils.formatEther(this.balances[asset.symbol]) *
                parseFloat(
                  ethers.utils.formatEther(asset.rate) / this.ethCurrencyRate
                )
            )
          : 0;
        this.totalValues.assets += this.values[asset.symbol];
      }

      this.$emit("loaded", true);
      this.loading = false;
    },

    setCurrency: async function (currency) {
      if (currency === this.currency) return;

      if (this.currency !== "")
        showNotification(
          "success",
          "switching to " + currency + ", please wait",
          undefined,
          0
        );

      // Use Covalent for ETH/USD, use Twelvedata for USD/currency
      const oldCurrency = this.currency;
      const conversions = await Promise.all([
        getUSDPrice("ETH"),
        currency === "USD" ? 1 : await getPrice("USD", currency),
      ]);
      this.ethCurrencyRate = 1 / (conversions[0] * conversions[1]);
      closeAllNotifications();
      this.currency = currency;

      if (oldCurrency === "") return;

      this.prices[this.nativeToken] = this.nativePrice =
        parseFloat(ethers.utils.formatEther(this.oneEth)) /
        this.ethCurrencyRate;
      this.values[this.nativeToken] =
        parseFloat(ethers.utils.formatEther(this.nativeBalance)) *
        this.nativePrice;
      this.totalValues = {
        fxTokens: 0,
        collateral: this.values[this.nativeToken],
      };

      if (this.walletFxTokens.length === 0) return;

      for (let walletFxToken of this.walletFxTokens) {
        walletFxToken.balance = this.balances[walletFxToken.symbol];
        this.prices[walletFxToken.symbol] =
          parseFloat(ethers.utils.formatEther(walletFxToken.rate)) /
          this.ethCurrencyRate;
        this.values[walletFxToken.symbol] = this.balances[walletFxToken.symbol]
          ? parseFloat(
              ethers.utils.formatEther(this.balances[walletFxToken.symbol]) *
                parseFloat(
                  ethers.utils.formatEther(walletFxToken.rate) /
                    this.ethCurrencyRate
                )
            )
          : 0;
        this.totalValues.fxTokens += this.values[walletFxToken.symbol];
      }

      for (let walletCollateral of this.walletCollaterals) {
        const symbol = walletCollateral.symbol;
        walletCollateral.balance = this.balances[symbol];
        this.prices[symbol] =
          parseFloat(ethers.utils.formatEther(walletCollateral.rate)) /
          this.ethCurrencyRate;
        this.values[symbol] = this.balances[symbol]
          ? (ethers.utils.formatUnits(
              this.balances[symbol],
              walletCollateral.decimals
            ) *
              parseFloat(ethers.utils.formatEther(walletCollateral.rate))) /
            this.ethCurrencyRate
          : 0;

        this.totalValues.collateral += this.values[symbol];
      }
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },

    capitalise(str) {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    },

    send(token, decimals = 18) {
      this.sendToken = token;
      this.sendTokenBalance = this.balances[this.sendToken];
      this.sendTokenDecimals = decimals;
      this.sendModal.show();
    },

    convert(token) {
      store.commit("setToken", { token: token, type: "from" });
      router.replace({ path: "/convert" });
    },

    addToken(addToken) {
      const tokenDetails = this.sdk.protocol.fxTokens.find(
        (token) => token.symbol === addToken
      );

      const tokenToAdd = {
        symbol: addToken,
        address: tokenDetails.address,
        decimals: tokenDetails.decimals,
        image: `https://app.handle.fi/${addToken}Logo.png`,
      };

      addAssetToWallet(tokenToAdd);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;

.hfi-wallet-table-header {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green;
  border-bottom: none;
}

.hfi-wallet-header-row {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green !important;
}

.hfi-top-n1 {
  top: -1px;
}

.hfi-token-cell {
  width: 116px;
}

.hfi-collateral-cell {
  width: 84px;
}

.uk-table {
  border-top: unset;
}

.fa-stack {
  margin-top: -4px;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.25em;
}

.fa-stack-halfx {
  font-size: 0.5em;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.hfi-action-column {
  width: 50px;
}

.hfi-button-group-button {
  padding: 0;
  margin: -6px 0 -4px;
  line-height: 28px;
}

.hfi-button-group-button-first {
  margin-right: -1px;
}

.hfi-button-group-button-not-first {
  border-right: unset !important;
}

.hfi-button-group-button-last {
}

.hfi-token-column {
  width: 250px;
}
.hfi-select-currency {
  display: flex;
  align-items: center;
  margin: -2px -8px -1px 0 !important;
}

@media screen and (max-width: 959px) {
  .hfi-empty-cell {
    padding: 0;
  }

  .uk-form-horizontal {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    .uk-form-controls {
      margin-left: 0 !important;
    }
  }

  .hfi-table-border-left {
    border: 0;
  }

  tr.hfi-wallet-header-row.row-2 {
    display: none;
  }

  td[data-label="actions"] {
    text-align: right !important;
  }

  .wallet {
    .hfi-wallet-table-header {
      border-bottom: unset !important;
      flex-direction: column;

      h4 {
        text-align: center;
      }

      div span {
        display: block;
      }

      label.uk-form-label {
        display: none !important;
      }

      .hfi-select-currency {
        align-self: flex-end;
        margin-top: -32px !important;
      }
    }
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
  }

  table td::before {
    content: attr(data-label);
    position: relative;
    float: left;
  }

  table td[scope="row"]::before {
    font-weight: bold;
  }

  table td span[data-label]::before {
    content: attr(data-label);
    position: relative;
    float: left;
    font-weight: bold;
  }

  table td a[data-label]::before {
    content: attr(data-label);
    position: relative;
    float: left;
    font-weight: bold;
  }

  table td {
    display: block;
    text-align: right;
  }

  table td:last-child {
    border-bottom: 0;
  }

  .uk-table-divider > tr:not(:first-child),
  .uk-table-divider > :not(:first-child) > tr,
  .uk-table-divider > :first-child > tr:not(:first-child) {
    border-top-width: 2px;

    td:first-child {
      border-bottom: 1px solid handle.$green;
    }
  }
}

@media screen and (max-width: 767px) {
  .wallet {
    .hfi-wallet-table-header {
      flex-direction: inherit !important;

      h4 {
        text-align: left;
      }
    }
  }

  .hfi-totals {
    display: none;
  }
}
</style>
