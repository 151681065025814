<template>
  <div class="spinner uk-flex">
    <input
      :disabled="disabled || inputDisabled"
      :class="
        'uk-input ' +
        (inputClasses ? inputClasses : '') +
        (alert ? 'hfi-danger' : '')
      "
      type="number"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :step="step"
      :value="textValue"
      @change.prevent="handleUserInput"
      @input.prevent="handleUserInput"
    />
    <div :class="`spinner-nav ${disabled ? 'disabled-opacity' : ''}`">
      <button
        :disabled="disabled"
        :class="
          'uk-button uk-button-small spinner-button spinner-up ' +
          (alert ? 'hfi-danger' : '')
        "
        @click.prevent="incrementValue('up')"
      >
        <span
          class="uk-form-icon uk-form-icon-flip"
          uk-icon="icon: chevron-up"
        ></span>
      </button>

      <button
        :disabled="disabled"
        :class="
          'uk-button uk-button-small spinner-button spinner-down ' +
          (alert ? 'hfi-danger' : '')
        "
        @click.prevent="incrementValue('down')"
      >
        <span
          class="uk-form-icon uk-form-icon-flip"
          uk-icon="icon: chevron-down"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { getDecimalsAmount } from "@/utils/utils";

export default {
  name: "NumberInput",
  props: [
    "inputClasses",
    "placeholder",
    "value",
    "decimals",
    "step",
    "min",
    "max",
    "disabled",
    "inputDisabled",
    "alert",
    "setValueOnAmount",
  ],
  data() {
    return {
      textValue: "",
      internalValue: ethers.BigNumber.from(0),
      ethers,
    };
  },
  mounted: function () {
    if (this.setValueOnAmount) {
      this.textValue = ethers.utils.formatEther(
        getDecimalsAmount(this.value, this.decimals || 18, 18)
      );
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        if (!newValue.eq) return;
        if (newValue.eq(this.internalValue)) return;
        this.textValue = ethers.utils.formatEther(
          getDecimalsAmount(newValue, this.decimals || 18, 18)
        );
        this.internalValue = newValue;
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit("change", this.internalValue);
      this.$emit("input", this.internalValue);
    },
    incrementValue(dir) {
      const oldValue = this.internalValue;
      let increment = getDecimalsAmount(
        ethers.utils.parseEther(this.step?.toString() || "1"),
        18,
        this.decimals || 18
      );

      let newValue =
        dir === "down" ? oldValue.sub(increment) : oldValue.add(increment);

      if (this.min && newValue.lt(this.min)) newValue = oldValue;

      if (this.max && newValue.gt(this.max)) newValue = oldValue;

      this.internalValue = newValue;
      this.emitChange();
      this.textValue = ethers.utils.formatEther(
        getDecimalsAmount(newValue, this.decimals || 18, 18)
      );
    },
    handleUserInput({ target }) {
      const split = target.value.split(".");
      this.textValue =
        split.length === 1
          ? target.value
          : `${split[0]}.${split[1].substr(0, 18)}`;
      this.internalValue = getDecimalsAmount(
        ethers.utils.parseEther(this.textValue || "0"),
        18,
        this.decimals || 18
      );
      this.emitChange();
    },
  },
};
</script>

<style scoped>
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  float: left;
  -moz-appearance: textfield;
}

.spinner-nav {
  float: left;
  position: relative;
  height: 42px;
}

.uk-form-icon {
  color: inherit;
  width: auto;
}

.spinner-button {
  position: relative;
  padding: 0;
  cursor: pointer;
  width: 20px;
  text-align: center;
  color: inherit;
  font-size: 10px;
  font-family: inherit;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.spinner-button:disabled {
  cursor: default;
  opacity: 0.5;
}

.spinner-button:hover {
}

.spinner-button.spinner-up {
  position: absolute;
  height: 50%;
  top: 0;
}

.spinner-button.spinner-down {
  border-top: none;
  position: absolute;
  height: 50%;
  bottom: 2px;
}
</style>
