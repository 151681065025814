<template>
  <div class="uk-margin">
    <label
      class="uk-form-label uk-text-right"
      style="width: auto; margin-top: 3px"
      for="selectCurrency"
    >
      {{ label ? label : "currency" }}
    </label>
    <div class="uk-form-controls" style="margin-left: 8px">
      <Select
        id="selectCurrency"
        class="hfi-currency-boundary"
        boundaryClass="hfi-currency-boundary"
        :selectClasses="'uk-width-expand' + selectClasses ? selectClasses : ''"
        inputClasses="uk-text-left hfi-currency-button"
        :options="currencies"
        :useFlags="false"
        :icons="currencyIcons"
        :value="value ? value : 'USD'"
        @change="setFxToken"
        :disabled="disabled"
        offset="1"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";

export default {
  name: "SelectCurrency",
  comments: [Select],
  props: ["value", "disabled", "selectClasses", "inputClasses", "label"],
  data: () => ({
    currencies: ["USD", "AUD", "CNY", "EUR", "JPY", "KRW", "SGD", "GBP"],
    currencyIcons: [
      "usdLogo.png",
      "audLogo.png",
      "cnyLogo.png",
      "eurLogo.png",
      "jpyLogo.png",
      "krwLogo.png",
      "sgdLogo.png",
      "gbpLogo.png",
    ],
  }),
  methods: {
    setFxToken(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style type="scss">
#selectCurrency-dropdown {
  min-width: auto !important;
}
</style>
