<template>
  <div
    id="send-modal"
    class="uk-flex-top uk-margin-remove-top"
    uk-modal="bgClose: false; container: false;"
  >
    <form
      novalidate
      autocomplete="off"
      class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <a @click.prevent="closeSendModal" class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>

        <h4 class="hfi-modal-title uk-margin-small-bottom">
          <i class="fal fa-share"></i>
          send {{ token }}
        </h4>

        <div class="uk-margin-top">
          <label class="uk-form-label uk-flex uk-width-expand" for="toAddress">
            to address
          </label>
          <div
            class="uk-form-controls uk-position-relative uk-flex uk-margin-bottom"
          >
            <input
              id="toAddress"
              class="uk-input"
              placeholder="recipient address"
              v-model="toAddress"
              :disabled="!account || processingTransaction"
              autocomplete="off"
            />
          </div>
        </div>

        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between uk-margin-small-top"
          for="sendAmount"
        >
          <span>amount</span>
          <span>
            available:
            {{ formatEther(balance, 4, decimals) }}
            {{ token }}
          </span>
        </label>

        <div
          class="uk-form-controls uk-position-relative uk-flex uk-margin-small-bottom"
        >
          <NumberInput
            class="uk-width-expand"
            id="sendAmount"
            placeholder="amount to send"
            :value="sendAmount"
            :decimals="decimals"
            :min="ethers.BigNumber.from(0)"
            :step="0.001"
            :max="balance"
            :alert="!hasEnoughBalanceToSend"
            @input="setSendAmount"
            :disabled="!account || processingTransaction"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click.prevent="setMaxSendAmount"
            :disabled="!account || processingTransaction"
          >
            max
          </button>
        </div>
        <div>
          <button
            class="uk-button uk-button-primary uk-width-expand hfi-button uk-margin-small-top"
            :disabled="
              !account ||
              !validToAddress ||
              processingTransaction ||
              sendAmount.eq(0) ||
              !hasEnoughBalanceToSend
            "
            @click.prevent="send"
          >
            {{ account ? sendButtonText : "Connect wallet to continue" }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import { getDecimalsAmount, getExplorerMeta } from "@/utils/utils";
import NumberInput from "@/components/NumberInput";
import {
  showNotification,
  closeAllNotifications,
  formatPrice,
} from "@/utils/utils";
import UIkit from "uikit";
import Token from "@/types/Token";
import sendTransaction from "@/contracts/utils/sendTransaction";
import { signer } from "@/utils/wallet";
import Network from "@/types/Network";

export default {
  name: "SendModal",
  components: {
    NumberInput,
  },
  props: {
    token: { type: String, default: "" },
    balance: {
      type: Object,
      validator: (value) => ethers.BigNumber.isBigNumber(value),
      default: ethers.BigNumber.from(0),
    },
    decimals: { type: Number, default: 18 },
  },

  data() {
    return {
      loading: true,
      toAddress: "",
      sendAmount: ethers.BigNumber.from(0),
      maxSendAmount: ethers.BigNumber.from(0),
      processingTransaction: false,
      ethers,
      formatPrice,
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
    };
  },
  watch: {
    token: function () {
      this.sendAmount = ethers.BigNumber.from(0);
    },
  },
  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },

    sendButtonText() {
      return this.processingTransaction
        ? "processing"
        : !this.hasEnoughBalanceToSend
        ? "insufficient funds"
        : "send";
    },
    hasEnoughBalanceToSend() {
      if (!ethers.BigNumber.isBigNumber(this.balance)) return false;
      return (
        ethers.BigNumber.isBigNumber(this.balance) &&
        this.sendAmount.lte(this.balance)
      );
    },
    validToAddress() {
      return (
        this.toAddress.slice(0, 2) === "0x" && this.toAddress.length === 42
      );
    },

    nativeToken() {
      return this.network === Network.polygon ? "MATIC" : "ETH";
    },

    isNativeToken() {
      return this.token === this.nativeToken;
    },
  },

  async mounted() {
    this.toAddress = "";
    this.sendAmount = ethers.BigNumber.from(0);
  },

  methods: {
    setSendAmount(value) {
      this.sendAmount = value;
      return value;
    },

    async send() {
      this.processingTransaction = true;

      const addressExplorerMeta = await getExplorerMeta(
        this.toAddress,
        "address"
      );

      const confirmMessage = `follow wallet instructions to confirm your transfer of ${this.formatEther(
        this.sendAmount,
        this.token.slice(0, 2) === "fx" ? 2 : 4,
        this.decimals
      )} ${this.token} to <a target="_blank" href="${
        addressExplorerMeta.typeUrl
      }">${this.toAddress.slice(0, 6)}...${this.toAddress.slice(-4)}</a>`;

      showNotification("warning", confirmMessage, undefined, 0);

      if (this.isNativeToken) {
        try {
          const transaction = await signer.sendTransaction({
            to: this.toAddress,
            value: this.sendAmount,
          });

          closeAllNotifications();

          const txExplorerMeta = await getExplorerMeta(transaction.hash, "tx");

          showNotification(
            "success",
            `transfer of ${this.formatEther(
              this.sendAmount,
              4,
              this.decimals
            )} ${this.token} to <a target="_blank" href="${
              addressExplorerMeta.typeUrl
            }">${this.toAddress.slice(0, 6)}...${this.toAddress.slice(
              -4
            )}</a> successful. ${txExplorerMeta.typeMessageWithLink}`,
            undefined,
            0
          );

          this.sendAmount = ethers.BigNumber.from(0);
          this.toAddress = "";
          this.closeSendModal();
          this.$emit("sent");
          this.processingTransaction = false;
        } catch (error) {
          this.notifyError(error);
        }

        return; // from nativeToken block
      }

      // the sdk is always initialized to arbitrum so e connect their current signer
      const tokenInstance = this.sdk.contracts[this.token]
        ? this.sdk.contracts[this.token].connect(signer)
        : this.sdk.contracts[this.token.toLowerCase()].connect(signer); // for FOREX -> forex currently

      try {
        const receipt = await tokenInstance.transfer(
          this.toAddress,
          this.sendAmount
        );

        closeAllNotifications();

        showNotification(
          "success",
          "transfer processing, please wait...",
          undefined,
          0
        );

        const transaction = await receipt.wait(
          store.state.network === "hardhat" ? 1 : 2
        );

        closeAllNotifications();

        const txExplorerMeta = await getExplorerMeta(
          transaction.transactionHash,
          "tx"
        );

        showNotification(
          "success",
          `transfer of ${this.formatEther(
            this.sendAmount,
            this.token.slice(0, 2) === "fx" ? 2 : 4,
            this.decimals
          )} ${this.token} to <a target="_blank" href="${
            addressExplorerMeta.typeUrl
          }">${this.toAddress.slice(0, 6)}...${this.toAddress.slice(
            -4
          )}</a> successful. ${txExplorerMeta.typeMessageWithLink}`,
          undefined,
          0
        );

        this.sendAmount = ethers.BigNumber.from(0);
        this.toAddress = "";
        this.closeSendModal();
        this.$emit("sent");
        this.processingTransaction = false;
        return transaction;
      } catch (error) {
        this.notifyError(error);
      }
    },

    notifyError(error) {
      closeAllNotifications();
      console.error(error);
      const errorMessage = error.data?.message ?? error.message;
      console.log(errorMessage);
      showNotification(
        "error",
        errorMessage == "transaction failed: " || errorMessage.length == 0
          ? "something went wrong"
          : errorMessage
      );
      this.processingTransaction = false;
    },

    async setMaxSendAmount() {
      const max = this.balance;
      await this.setSendAmount(max);
    },

    closeSendModal() {
      UIkit.modal("#send-modal").hide();
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;

.hfi-vaults-container {
  max-width: calc(100vw - 30px);
}

@media (min-width: 640px) {
  .hfi-vaults-container {
    max-width: calc(100vw - 60px);
  }
}

@media (min-width: 960px) {
  .hfi-vaults-container {
    max-width: calc(100vw - 80px);
  }
}

.hfi-vaults-table-header {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green;
}

.uk-dropdown {
  z-index: 1031;
}

.uk-dropdown-nav .uk-nav-header {
  color: handle.$green !important;
  text-transform: none;
}

.uk-dropdown-nav .uk-nav-divider {
  border-top-color: handle.$green;
}

.uk-dropdown-nav > li {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.uk-dropdown-nav > li:not(.uk-active) {
  color: handle.$green;
  background-color: handle.$background;
}

.uk-dropdown-nav > li:not(.uk-nav-header):hover,
.uk-dropdown-nav > li:not(.uk-nav-header):focus,
.uk-dropdown-nav > li:not(.uk-nav-header).uk-active {
  color: handle.$background;
  background-color: handle.$green !important;
  a {
    color: inherit;
  }
}

.hfi-top-n1 {
  top: -1px;
}

.hfi-margin-top-n2 {
  margin-top: -2px;
}

.hfi-token-cell {
  width: 116px;
}

.hfi-collateral-cell {
  width: 84px;
}

.uk-table {
  border-top: unset;
  margin-top: -2px !important;
}

.fa-stack {
  margin-top: -4px;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.25em;
}

.fa-stack-halfx {
  font-size: 0.5em;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 959px) {
  .hfi-empty-cell {
    padding: 0;
  }

  .hfi-table-border-left {
    border: 0;
  }

  .vaults {
    .hfi-vaults-table-header {
      flex-direction: column;

      h4 {
        text-align: center;
      }
    }
  }
  .vault {
    .hfi-vaults-table-header {
      flex-direction: column;

      h4 {
        text-align: center;
        margin-bottom: 6px !important;

        span {
          display: inline;
        }
      }
    }
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
  }

  table td {
    display: block;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    position: relative;
    float: left;
  }

  table td[scope="row"]::before {
    font-weight: bold;
  }

  table td span[data-label]::before {
    content: attr(data-label);
    position: relative;
    float: left;
    font-weight: bold;
  }

  table td a[data-label]::before {
    content: attr(data-label);
    position: relative;
    float: left;
    font-weight: bold;
  }

  table td:last-child {
    border-bottom: 0;
  }

  .uk-table-divider > tr:not(:first-child),
  .uk-table-divider > :not(:first-child) > tr,
  .uk-table-divider > :first-child > tr:not(:first-child) {
    border-top-width: 2px;

    td:first-child {
      border-bottom: 1px solid handle.$green;
    }
  }
}
</style>
